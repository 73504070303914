import React, { useState, useEffect, useRef, memo, lazy, Suspense,useCallback } from "react";
import styled from "styled-components";
import axios from "axios";
//components
import HeaderLayout from "../styles/HeaderLayout";



const NavBar = memo(lazy(() => import('./NavBar/NavBar')));
const ButtonsVolant = memo(lazy(() => import('./NavBar/ButtonsVolant')));
const BandeauInfoHP = memo(lazy(() => import('./BandeauInfo/BandeauInfo')));

const StickyHeader = styled(HeaderLayout)`
  @media (min-width: 1200px) {
    opacity: ${(props) => (props.isVisible ? "1" : "0")};
    transition:
      opacity 0.3s ease-in-out,
      transform 0.5s ease-in-out;
    position: ${(props) => (props.isVisible ? "sticky" : "fixed")};
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    transform: translateY(${(props) => (props.isVisible ? "0%" : "-100%")})
      scale(${(props) => (props.isVisible ? "1" : "0.9")});
  }
`;
const CLE_API = process.env.GATSBY_CLE_API;
const Header = () => {
  const [showBandeauInfo, setShowBandeauInfo] = useState(false);
  const classNameB = showBandeauInfo ? "" : "hiddenBandeau";
  const [dataBannieres, setDataBannieres] = useState([]);
  const [contientForm, setContientForm] = useState(false);
  const [showBtnDoc, setShowBtnDoc] = useState(false);
  const [showBtnMenu, setShowBtnMenu] = useState(true);
  const [buttonsVolantsScrollValue, setButtonsVolantsScrollValue] =
    useState(400);
  const [showBtn, setShowBtn] = useState(true);
  const [scrollValue, setScrollValue] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
const [IDContenu, setIDContenu] = useState(0);

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visibleBanners, setVisibleBanners] = useState([]);
  const timeoutsRef = useRef([]);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 1200);
    setShowBtnDoc(contientForm);
    setShowBtnMenu(isMobile);
  }, [contientForm, isMobile]);

  useEffect(() => {
    timeoutsRef.current.forEach(clearTimeout);
    timeoutsRef.current = [];
    const largestDelay = Math.max(...dataBannieres.map(banner => banner.DelaiAffichage));
    let cumulativeDelay = 0; // Initialize cumulative delay
    const newVisibleBanners = dataBannieres.map(() => false);
  
    dataBannieres.forEach((banner, index) => {
      cumulativeDelay = banner.DelaiAffichage; // Accumulate delay
      const timeoutId = setTimeout(() => {
        newVisibleBanners[index] = true;
        setVisibleBanners([...newVisibleBanners]);
      }, cumulativeDelay * 1000); // Use cumulative delay for sequential display
  
      timeoutsRef.current.push(timeoutId);

      const showBandeauInfoTimeoutId = setTimeout(() => {
        setShowBandeauInfo(true);
      }, largestDelay * 1000);
    
      timeoutsRef.current.push(showBandeauInfoTimeoutId);
    
    });
  console.log(`cumulativeDelay: ${cumulativeDelay}`);
    // Set bandeau info to be shown after the cumulative delay of all banners
 
    return () => {
      timeoutsRef.current.forEach(clearTimeout);
    };
  }, [dataBannieres]);
  function handleCloseSlide(i) {
    const newDataBannieres = dataBannieres.filter((item, index) => index !== i);
    setDataBannieres(newDataBannieres);
  }
// useEffect(() => {
//   console.log(`showBandeauInfo changed to: ${showBandeauInfo}`);
// }, [showBandeauInfo]);
  const [isVisible, setIsVisible] = useState(true);
  const lastScrollTop = useRef(0);

  const navbarHeight = 0;
  useEffect(() => {
    (async () => {
      try {
        const res = await axios.post(`/ysw/bannieres`, { Slug: `${window.location.pathname}` });
        setDataBannieres(res.data?.Bannieres ? res.data.Bannieres : []);
      } catch (err) {
        console.log(err);
        setShowBandeauInfo(false);
      }
    })();
  }, []);
// Handle scroll events to show/hide the navbar
const handleScrollFilter = useCallback(() => {
  let st = window.scrollY || document.documentElement.scrollTop;

  if (st > lastScrollTop.current && st > navbarHeight) {
    setIsVisible(false);
  } else {
    setIsVisible(true);
  }
  lastScrollTop.current = st <= 0 ? 0 : st; // For Mobile or negative scrolling
}, [navbarHeight]);

useEffect(() => {
  window.addEventListener('scroll', handleScrollFilter);
  return () => {
    window.removeEventListener('scroll', handleScrollFilter);
  };
}, [handleScrollFilter]);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`/ysw/boutonflotants/?{"Act":"BoutonFlotants", "Slug":"${window.location.pathname}","CleCtrl":"${CLE_API}"}`);
        console.log(res, "response");
        setIDContenu(res.data.IDContenu);
        setContientForm(res.data.contientForm1);
        setShowBtnDoc(res.data.contientForm1);
        setShowBtnMenu(isMobile);
        const scrollValue =
          res?.data?.scrollValue
            ? isMobile
              ? 750
              : 950
            : 400;
        setButtonsVolantsScrollValue(scrollValue);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    })();
  }, []);

  return (
    <>
      <StickyHeader isVisible={isVisible}>
        {  showBandeauInfo && (
          <Suspense> 
          <BandeauInfoHP
            dataBannieres={dataBannieres}
            visibleBanners={visibleBanners}
            handleCloseSlide={handleCloseSlide}
          />
          </Suspense>
        )}
        <NavBar
          classNameB={classNameB}
          isVisible={isVisible}
          showBtnDoc={showBtnDoc}
        />
      </StickyHeader>
<Suspense>   
      <ButtonsVolant
        idContenu={IDContenu}
        showBtn={showBtn}
        showBtnDoc={showBtnDoc}
        showBtnMenu={showBtnMenu}
        scrollValue={scrollValue}
      />
</Suspense>
    </>
  );
};
export default Header;
